.profile-container {
  padding: 1rem;
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.profile-content {
  margin-top: 1rem;
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}

.profile-header {
  margin-bottom: 2rem;
  text-align: center;
}

.profile-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #0066cc;
  margin: 0 auto 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-avatar .initials {
  color: white;
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
}

.profile-header h1 {
  margin: 0 0 0.5rem 0;
  font-size: 1.5rem;
  font-weight: 600;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.profile-header p {
  margin: 0;
  font-size: 1rem;
  color: #666;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.profile-info {
  display: grid;
  gap: 1rem;
}

.info-item {
  display: grid;
  gap: 0.75rem;
  padding: 0.75rem 0;
  border-bottom: 1px solid #eee;
  overflow: hidden;
}

.info-label {
  font-weight: 600;
  color: #666;
  white-space: nowrap;
}

.info-value {
  color: #333;
  word-break: break-word;
  hyphens: auto;
  min-width: 0;
}

.info-value a {
  color: #0066cc;
  text-decoration: none;
  display: inline-block;
  word-break: break-all;
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.info-value a:hover {
  text-decoration: underline;
}

.download-button {
  margin: 2rem auto 0;
  width: 100%;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.download-button:hover {
  background-color: #0052a3;
}

.download-button svg {
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
}

/* Desktop layout */
@media (min-width: 640px) {
  .profile-container {
    padding: 2rem;
  }

  .profile-content {
    padding: 2rem;
    margin-top: 2rem;
  }

  .info-item {
    grid-template-columns: 120px 1fr;
    align-items: baseline;
    gap: 1rem;
  }

  .profile-header h1 {
    font-size: 1.75rem;
  }

  .profile-header p {
    font-size: 1.1rem;
  }
}

/* Mobile layout */
@media (max-width: 639px) {
  .profile-container {
    padding: 1rem;
  }

  .profile-content {
    padding: 1rem;
    margin-top: 1rem;
  }

  .info-item {
    grid-template-columns: 1fr;
  }

  .info-label {
    font-size: 0.875rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  .info-value {
    font-size: 1rem;
    padding-bottom: 0.25rem;
  }

  .info-value a {
    display: block;
    padding: 0.5rem 0;
    max-width: calc(100vw - 2rem);
  }
}
