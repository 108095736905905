/* Features Page Container */
.features-container {
    max-width: 1200px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
  }
  
  .features-title {
    font-size: 36px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .features-description {
    font-size: 18px;
    margin-bottom: 30px;
    color: #555;
  }
  
  /* Features Grid */
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  /* Feature Card */
  .feature-card {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .feature-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }
  
  .feature-image {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  
  .feature-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #007bff;
  }
  
  .feature-description {
    font-size: 16px;
    color: #555;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .features-title {
      font-size: 28px;
    }
  
    .feature-title {
      font-size: 20px;
    }
  }
  