/* Contact Page Container */
.contact {
    min-height: calc(100vh - 200px);
    background: var(--surface);
    padding: clamp(60px, 10vw, 120px) clamp(20px, 5vw, 40px);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

/* Contact Hero Section */
.contact-hero {
    text-align: center;
    max-width: 800px;
    margin-bottom: 4rem;
}

.contact h2 {
    font-size: clamp(2.5rem, 6vw, 4rem);
    font-weight: 900;
    margin-bottom: 1rem;
    background: linear-gradient(135deg, var(--accent), var(--accent2));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 1.2;
}

.contact-description {
    font-size: clamp(1.1rem, 2.5vw, 1.3rem);
    line-height: 1.6;
    color: var(--text-light);
    margin-bottom: 2rem;
}

.contact-description-container {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
}

/* Contact Form Container */
.contact-form-container {
    width: 100%;
    max-width: 600px;
    background: white;
    padding: 3rem;
    border-radius: 2rem;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}

.contact-form-container::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(135deg, 
        rgba(59, 30, 255, 0.05) 0%,
        rgba(255, 45, 85, 0.05) 100%
    );
    opacity: 0.5;
}

/* Contact Form */
.contact-form {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.form-group {
    position: relative;
    width: 100%;
}

.form-group label {
    display: block;
    font-weight: 600;
    color: var(--text);
    margin-bottom: 0.5rem;
    font-size: 1rem;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    background: white;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    box-sizing: border-box;
}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-color: var(--accent);
    box-shadow: 0 0 0 4px rgba(59, 30, 255, 0.1);
}

.form-group textarea {
    resize: vertical;
    min-height: 150px;
}

/* Submit Button */
.submit-button {
    background: linear-gradient(135deg, var(--accent), var(--accent2));
    color: white;
    padding: 1rem 2rem;
    border: none;
    border-radius: 100px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-top: 1rem;
}

.submit-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 30px rgba(59, 30, 255, 0.2);
}

.submit-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
}

/* Error and Success Messages */
.error {
    color: var(--accent2);
    font-size: 0.9rem;
    margin-top: 0.5rem;
}

.response-message {
    margin-top: 1.5rem;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 600;
    color: #22c55e;
    animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .contact {
        padding: 3rem 1rem;
        margin: 0;
        width: 100%;
    }

    .contact-form-container {
        padding: 1.5rem;
        border-radius: 1.5rem;
        width: 100%;
        margin: 0;
    }

    .form-group input,
    .form-group textarea {
        padding: 0.875rem;
    }

    .submit-button {
        width: 100%;
    }
}
