/* Modern Footer.css */
.footer {
    background: linear-gradient(135deg, var(--secondary), #000000);
    color: white;
    padding: 80px 0 40px;
    position: relative;
    overflow: hidden;
}

.footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(to right, 
        transparent,
        var(--accent),
        var(--accent2),
        transparent
    );
}

.footer::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: 
        radial-gradient(circle at 20% 20%, rgba(59, 30, 255, 0.1) 0%, transparent 50%),
        radial-gradient(circle at 80% 80%, rgba(255, 45, 85, 0.1) 0%, transparent 50%);
    pointer-events: none;
}

.footer-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 40px;
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 60px;
}

.footer-brand {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.footer-logo {
    height: 70px;
    width: auto;
    margin-bottom: 20px;
}

.footer-tagline {
    font-size: 1.1rem;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.8);
}

.footer-links {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.footer-links h3 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 20px;
    background: linear-gradient(90deg, var(--accent), var(--accent2));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.footer-link {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    transition: all 0.3s ease;
    display: inline-block;
}

.footer-link:hover {
    color: white;
    transform: translateX(5px);
}

.footer-contact {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.footer-contact h3 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 10px;
    background: linear-gradient(90deg, var(--accent), var(--accent2));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.contact-info {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contact-item {
    display: flex;
    align-items: center;
    gap: 10px;
    color: rgba(255, 255, 255, 0.8);
}

.contact-item svg {
    color: var(--accent);
}

.footer-social {
    display: flex;
    gap: 20px;
    margin-top: 30px;
}

.social-link {
    color: rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease;
}

.social-link:hover {
    color: var(--accent);
    transform: translateY(-3px);
}

.footer-bottom {
    margin-top: 60px;
    padding-top: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.9rem;
}

@media (max-width: 968px) {
    .footer-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .footer {
        padding: 60px 0 30px;
    }
    
    .footer-container {
        grid-template-columns: 1fr;
        gap: 40px;
        padding: 0 20px;
    }
}
