/* Bold Modern Design with Dynamic Banner */
:root {
    --primary: #3b1eff;
    --primary-light: #6247ff;
    --secondary: #0c0118;
    --accent: #ff2d55;
    --accent2: #00f2fe;
    --background: #ffffff;
    --surface: #fafafa;
    --text: #000000;
    --text-light: #2d2d2d;
}

@keyframes gradientFlow {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@keyframes float {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes pulse {
    0% { transform: scale(1); opacity: 0.5; }
    50% { transform: scale(1.2); opacity: 0.8; }
    100% { transform: scale(1); opacity: 0.5; }
}

@keyframes rotate {
    from { transform: rotate(0deg) translate(20%, 0) rotate(0deg); }
    to { transform: rotate(360deg) translate(20%, 0) rotate(-360deg); }
}

@keyframes morphing {
    0% { border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%; }
    25% { border-radius: 70% 30% 30% 70% / 70% 70% 30% 30%; }
    50% { border-radius: 30% 70% 70% 30% / 70% 30% 30% 70%; }
    75% { border-radius: 70% 30% 30% 70% / 30% 70% 70% 30%; }
    100% { border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%; }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Hero Section */
.hero {
    position: relative;
    min-height: 100vh;
    padding: 0;
    background: var(--secondary);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
}

.hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(120deg, 
        rgba(59, 30, 255, 0.7),
        rgba(255, 45, 85, 0.7),
        rgba(0, 242, 254, 0.7));
    background-size: 300% 300%;
    animation: gradientFlow 15s ease infinite;
    mix-blend-mode: hard-light;
}

.hero::after {
    content: '';
    position: absolute;
    width: 600px;
    height: 600px;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 0%, transparent 70%);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: pulse 10s ease-in-out infinite;
}

.hero-content {
    position: relative;
    z-index: 2;
    padding: 0 24px;
    animation: float 6s ease-in-out infinite;
}

.hero h1 {
    font-size: clamp(4rem, 12vw, 8rem);
    font-weight: 900;
    line-height: 0.9;
    letter-spacing: -0.03em;
    color: white;
    text-align: center;
    margin: 0 auto;
    padding: 0;
    text-transform: uppercase;
    background: linear-gradient(to right, #fff, #ffffffcc);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-shadow: 0 0 40px rgba(255, 255, 255, 0.3);
}

.hero h1 span {
    display: block;
    font-size: 0.5em;
    font-weight: 200;
    letter-spacing: 0.2em;
    margin-bottom: 1rem;
    color: var(--accent2);
    text-shadow: none;
    opacity: 0.9;
}

.hero p {
    font-size: clamp(1.25rem, 3vw, 1.75rem);
    line-height: 1.4;
    color: rgba(255, 255, 255, 0.9);
    max-width: 800px;
    margin: 2rem auto;
    text-align: center;
    font-weight: 300;
    letter-spacing: 0.02em;
}

.gs-buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 3rem;
}

.cta-button {
    padding: 20px 50px;
    font-size: 1.25rem;
    font-weight: 600;
    border-radius: 100px;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
    overflow: hidden;
}

.cta-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    transform: translateX(-100%);
    transition: 0.5s;
}

.cta-button:hover::before {
    transform: translateX(100%);
}

.cta-button:not(.secondary-button) {
    background: var(--accent);
    color: white;
    border: none;
    box-shadow: 0 10px 30px rgba(255, 45, 85, 0.3);
}

.cta-button.secondary-button {
    background: transparent;
    color: white;
    border: 2px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
}

.cta-button:hover {
    transform: translateY(-5px);
}

.cta-button:not(.secondary-button):hover {
    background: #ff1745;
    box-shadow: 0 15px 40px rgba(255, 45, 85, 0.4);
}

.cta-button.secondary-button:hover {
    border-color: white;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
}

/* Features Section */
.features {
    padding: clamp(40px, 8vw, 120px) clamp(16px, 4vw, 24px);
    background: linear-gradient(135deg, 
        rgba(59, 30, 255, 0.05) 0%,
        rgba(255, 45, 85, 0.05) 100%
    );
    position: relative;
    overflow: hidden;
}

.features h2 {
    font-size: clamp(2rem, 5vw, 4rem);
    font-weight: 900;
    text-align: center;
    margin-bottom: clamp(40px, 8vw, 80px);
    background: linear-gradient(135deg, var(--accent), var(--accent2));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    text-transform: uppercase;
    letter-spacing: -0.02em;
    line-height: 1.2;
}

.features h2::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 60px;
    background: linear-gradient(135deg, var(--accent), var(--accent2));
    opacity: 0.1;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    z-index: -1;
    animation: morphing 10s linear infinite;
}

.features-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    max-width: 1400px;
    margin: 0 auto;
    padding: 2rem;
}

.feature-item {
    flex: 1 1 300px;
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
    padding: 2rem;
    border-radius: 1rem;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
    outline: none;
}

.feature-item:hover,
.feature-item:focus {
    transform: translateY(-5px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}

.feature-item:focus-visible {
    outline: 2px solid var(--accent);
    outline-offset: 2px;
}

.feature-item img {
    width: clamp(48px, 10vw, 80px);
    height: clamp(48px, 10vw, 80px);
    margin-bottom: clamp(16px, 4vw, 32px);
    border-radius: clamp(8px, 2vw, 16px);
    object-fit: cover;
    transition: all 0.4s ease;
}

.feature-item h3 {
    font-size: clamp(1.25rem, 3vw, 1.75rem);
    margin-bottom: clamp(8px, 2vw, 16px);
    font-weight: 700;
    color: var(--text);
}

.feature-item p {
    font-size: clamp(1rem, 2vw, 1.125rem);
    line-height: 1.6;
    margin: 0;
    color: var(--text-light);
    flex-grow: 1;
}

/* Why Us Section */
.why-us {
    padding: clamp(40px, 8vw, 120px) clamp(16px, 4vw, 24px);
    background: linear-gradient(135deg, 
        rgba(59, 30, 255, 0.05) 0%,
        rgba(255, 45, 85, 0.05) 100%
    );
    position: relative;
    overflow: hidden;
}

.why-us h2 {
    font-size: clamp(2rem, 5vw, 4rem);
    font-weight: 900;
    text-align: center;
    margin-bottom: clamp(40px, 8vw, 80px);
    background: linear-gradient(135deg, var(--accent), var(--accent2));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    text-transform: uppercase;
    letter-spacing: -0.02em;
    line-height: 1.2;
}

.why-us h2::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 60px;
    background: linear-gradient(135deg, var(--accent), var(--accent2));
    opacity: 0.1;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    z-index: -1;
    animation: morphing 10s linear infinite;
}

.pitches-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    max-width: 1400px;
    margin: 0 auto;
    padding: 2rem;
}

.pitch-item {
    flex: 1 1 300px;
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
    border-radius: 1rem;
    overflow: hidden;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    cursor: pointer;
    outline: none;
}

.pitch-item:hover,
.pitch-item:focus {
    transform: translateY(-5px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}

.pitch-item:focus-visible {
    outline: 2px solid var(--accent);
    outline-offset: 2px;
}

.pitch-image {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.pitch-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s ease;
}

.pitch-item:hover .pitch-image img {
    transform: scale(1.1);
}

.pitch-content {
    padding: 2rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.pitch-content h3 {
    font-size: clamp(1.25rem, 3vw, 1.75rem);
    margin-bottom: clamp(8px, 2vw, 16px);
    font-weight: 700;
    color: var(--text);
}

.pitch-content p {
    font-size: clamp(1rem, 2vw, 1.125rem);
    line-height: 1.6;
    margin: 0;
    color: var(--text-light);
    flex-grow: 1;
}

/* Why Choose Us Section */
.why-choose-section {
    background-color: #0c0118;
    padding: 60px 20px;
    color: #ffffff;
}

.why-choose-title {
    text-align: center;
    margin-bottom: 50px;
}

.why-choose-title h2 {
    background: linear-gradient(45deg, #3b1eff, #ff2d55);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.service-card {
    background-color: #0c0118;
    border: 1px solid #3b1eff;
    border-radius: 10px;
    padding: 30px;
    margin: 20px 0;
    transition: transform 0.3s ease;
}

.service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(59, 30, 255, 0.2);
}

.service-card img {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
}

.service-card h3 {
    color: #ffffff;
    margin-bottom: 15px;
    font-size: 1.5rem;
}

.service-card p {
    color: #ffffff;
    opacity: 0.9;
    line-height: 1.6;
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    padding: 0 20px;
}

@media (max-width: 768px) {
    .services-grid {
        grid-template-columns: 1fr;
        padding: 0;
    }
    
    .service-card {
        margin: 10px 0;
    }
}

/* Responsive Design */
@media (max-width: 480px) {
    .features-grid,
    .pitches-grid {
        gap: 1rem;
        padding: 1rem;
    }

    .feature-item,
    .pitch-item {
        flex: 1 1 100%;
        min-width: 0;
        margin-bottom: 1rem;
    }

    .pitch-image {
        height: 150px;
    }

    .pitch-content {
        padding: 1rem;
    }
}

/* Touch Device Optimizations */
@media (hover: none) {
    .feature-item:active,
    .pitch-item:active {
        transform: scale(0.98);
        transition: transform 0.2s ease;
    }

    .pitch-image::after {
        opacity: 0.2;
    }
}

/* Animation for loading */
.feature-item,
.pitch-item {
    animation: fadeInUp 0.6s ease backwards;
}

.features-grid > *:nth-child(1) { animation-delay: 0.1s; }
.features-grid > *:nth-child(2) { animation-delay: 0.2s; }
.features-grid > *:nth-child(3) { animation-delay: 0.3s; }
.features-grid > *:nth-child(4) { animation-delay: 0.4s; }
.features-grid > *:nth-child(5) { animation-delay: 0.5s; }
.features-grid > *:nth-child(6) { animation-delay: 0.6s; }

.pitches-grid > *:nth-child(1) { animation-delay: 0.1s; }
.pitches-grid > *:nth-child(2) { animation-delay: 0.2s; }
.pitches-grid > *:nth-child(3) { animation-delay: 0.3s; }
.pitches-grid > *:nth-child(4) { animation-delay: 0.4s; }
