.about-page {
    min-height: calc(100vh - 200px);
    background: var(--surface);
}

.about-hero {
    padding: clamp(60px, 10vw, 120px) clamp(20px, 5vw, 40px);
    background: linear-gradient(135deg, 
        rgba(59, 30, 255, 0.05) 0%,
        rgba(255, 45, 85, 0.05) 100%
    );
    text-align: center;
    position: relative;
    overflow: hidden;
}

.about-hero::before {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(circle at 50% 50%, 
        rgba(59, 30, 255, 0.1),
        transparent 70%
    );
    opacity: 0.5;
    animation: pulse 10s ease-in-out infinite;
}

.about-hero h1 {
    font-size: clamp(2.5rem, 6vw, 4rem);
    font-weight: 900;
    margin-bottom: 1.5rem;
    background: linear-gradient(135deg, var(--accent), var(--accent2));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 1.2;
}

.about-hero p {
    font-size: clamp(1.1rem, 2.5vw, 1.3rem);
    line-height: 1.6;
    color: var(--text-light);
    max-width: 800px;
    margin: 0 auto;
}

.experience-section {
    padding: clamp(60px, 10vw, 120px) clamp(20px, 5vw, 40px);
    background: white;
}

.experience-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.experience-item {
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease;
    position: relative;
    overflow: hidden;
}

.experience-item::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(135deg, 
        rgba(59, 30, 255, 0.05) 0%,
        rgba(255, 45, 85, 0.05) 100%
    );
    opacity: 0;
    transition: opacity 0.3s ease;
}

.experience-item:hover {
    transform: translateY(-5px);
}

.experience-item:hover::before {
    opacity: 1;
}

.experience-item h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--text);
    position: relative;
}

.experience-item p {
    color: var(--text-light);
    line-height: 1.6;
    position: relative;
}

.stats-section {
    padding: clamp(60px, 10vw, 120px) clamp(20px, 5vw, 40px);
    background: linear-gradient(135deg, 
        rgba(59, 30, 255, 0.05) 0%,
        rgba(255, 45, 85, 0.05) 100%
    );
    text-align: center;
}

.stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
    max-width: 1000px;
    margin: 3rem auto 0;
}

.stat-item {
    padding: 2rem;
}

.stat-number {
    font-size: 3rem;
    font-weight: 900;
    margin-bottom: 0.5rem;
    background: linear-gradient(135deg, var(--accent), var(--accent2));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.stat-label {
    font-size: 1.1rem;
    color: var(--text);
    font-weight: 500;
}

.cta-section {
    padding: clamp(60px, 10vw, 120px) clamp(20px, 5vw, 40px);
    text-align: center;
    background: white;
}

.cta-section h2 {
    font-size: clamp(2rem, 4vw, 3rem);
    margin-bottom: 1.5rem;
    color: var(--text);
}

.cta-section p {
    font-size: clamp(1.1rem, 2.5vw, 1.3rem);
    color: var(--text-light);
    margin-bottom: 2rem;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.cta-button {
    background: linear-gradient(135deg, var(--accent), var(--accent2));
    color: white;
    padding: 1rem 3rem;
    border: none;
    border-radius: 100px;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 30px rgba(59, 30, 255, 0.2);
}

@media (max-width: 768px) {
    .experience-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .stats-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .experience-item {
        padding: 1.5rem;
    }
}

@media (max-width: 480px) {
    .stats-grid {
        grid-template-columns: 1fr;
    }

    .stat-item {
        padding: 1.5rem;
    }
}
