.service-page {
    min-height: 100vh;
    background: var(--surface);
}

.service-hero {
    padding: clamp(60px, 10vw, 120px) clamp(20px, 5vw, 40px);
    background: linear-gradient(135deg, 
        rgba(59, 30, 255, 0.05) 0%,
        rgba(255, 45, 85, 0.05) 100%
    );
    display: flex;
    align-items: center;
    gap: 4rem;
    position: relative;
    overflow: hidden;
}

.service-hero::before {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(circle at 50% 50%, 
        rgba(59, 30, 255, 0.1),
        transparent 70%
    );
    opacity: 0.5;
    animation: pulse 10s ease-in-out infinite;
}

.service-hero-content {
    flex: 1;
    z-index: 1;
}

.service-hero h1 {
    font-size: clamp(2.5rem, 6vw, 4rem);
    font-weight: 900;
    margin-bottom: 1rem;
    background: linear-gradient(135deg, var(--accent), var(--accent2));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 1.2;
}

.service-tagline {
    font-size: clamp(1.25rem, 3vw, 1.75rem);
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: var(--text);
}

.service-description {
    font-size: clamp(1.1rem, 2.5vw, 1.3rem);
    line-height: 1.6;
    color: var(--text-light);
    margin-bottom: 2rem;
    max-width: 600px;
}

.service-hero-image {
    flex: 1;
    max-width: 500px;
    border-radius: 2rem;
    overflow: hidden;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.service-hero-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s ease;
}

.service-hero-image:hover img {
    transform: scale(1.05);
}

.value-props {
    padding: clamp(60px, 10vw, 120px) clamp(20px, 5vw, 40px);
    background: white;
}

.value-props h2 {
    font-size: clamp(2rem, 4vw, 3rem);
    text-align: center;
    margin-bottom: 3rem;
    background: linear-gradient(135deg, var(--accent), var(--accent2));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.value-props-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
    max-width: 1400px;
    margin: 0 auto;
}

.value-prop-item {
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    text-align: center;
    transition: transform 0.3s ease;
}

.value-prop-item:hover {
    transform: translateY(-5px);
}

.value-prop-item .metric {
    font-size: 2rem;
    font-weight: 900;
    margin-bottom: 1rem;
    background: linear-gradient(135deg, var(--accent), var(--accent2));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.value-prop-item h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--text);
}

.value-prop-item p {
    color: var(--text-light);
    line-height: 1.6;
}

.why-choose-us {
    padding: clamp(60px, 10vw, 120px) clamp(20px, 5vw, 40px);
    background: linear-gradient(135deg, 
        rgba(59, 30, 255, 0.05) 0%,
        rgba(255, 45, 85, 0.05) 100%
    );
}

.why-choose-us h2 {
    font-size: clamp(2rem, 4vw, 3rem);
    text-align: center;
    margin-bottom: 3rem;
    background: linear-gradient(135deg, var(--accent), var(--accent2));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.why-us-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.why-us-item {
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    gap: 1rem;
    transition: transform 0.3s ease;
}

.why-us-item:hover {
    transform: translateY(-5px);
}

.check-icon {
    width: 2rem;
    height: 2rem;
    background: linear-gradient(135deg, var(--accent), var(--accent2));
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.why-us-item p {
    margin: 0;
    font-size: 1.1rem;
    color: var(--text-light);
    line-height: 1.6;
}

.success-story {
    padding: clamp(60px, 10vw, 120px) clamp(20px, 5vw, 40px);
    background: white;
    text-align: center;
}

.success-story h2 {
    font-size: clamp(2rem, 4vw, 3rem);
    margin-bottom: 3rem;
    background: linear-gradient(135deg, var(--accent), var(--accent2));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.success-story-content {
    max-width: 800px;
    margin: 0 auto;
}

.success-story h3 {
    font-size: clamp(1.5rem, 3vw, 2rem);
    color: var(--text);
    margin-bottom: 1rem;
}

.success-story .result {
    font-size: 1.25rem;
    color: var(--accent);
    font-weight: 600;
    margin-bottom: 2rem;
}

.success-story blockquote {
    font-size: 1.25rem;
    font-style: italic;
    color: var(--text-light);
    margin: 2rem 0;
    padding: 0 2rem;
    position: relative;
}

.success-metrics {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
}

.metric-item {
    background: linear-gradient(135deg, var(--accent), var(--accent2));
    color: white;
    padding: 1rem 2rem;
    border-radius: 100px;
    font-weight: 600;
}

.service-cta {
    text-align: center;
    padding: clamp(60px, 10vw, 120px) clamp(20px, 5vw, 40px);
    background: linear-gradient(135deg, 
        rgba(59, 30, 255, 0.05) 0%,
        rgba(255, 45, 85, 0.05) 100%
    );
}

.service-cta h2 {
    font-size: clamp(2rem, 4vw, 3rem);
    margin-bottom: 1rem;
    color: var(--text);
}

.service-cta p {
    font-size: clamp(1.1rem, 2.5vw, 1.3rem);
    color: var(--text-light);
    margin-bottom: 2rem;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.cta-button {
    background: linear-gradient(135deg, var(--accent), var(--accent2));
    color: white;
    padding: 1rem 3rem;
    border: none;
    border-radius: 100px;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 30px rgba(255, 45, 85, 0.2);
}

.services-container {
    background-color: #0c0118;
    padding: 60px 20px;
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
}

.service-card {
    background-color: #0c0118;
    border: 1px solid #3b1eff;
    border-radius: 10px;
    padding: 30px;
    margin: 20px 0;
    transition: transform 0.3s ease;
}

.service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(59, 30, 255, 0.2);
}

.service-icon {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
}

.service-title {
    color: #ffffff;
    margin-bottom: 15px;
    font-size: 1.5rem;
}

.service-description {
    color: #5f5b5b;
    opacity: 0.9;
    line-height: 1.6;
}

@media (max-width: 768px) {
    .service-hero {
        flex-direction: column;
        text-align: center;
        gap: 2rem;
    }

    .service-description {
        margin: 0 auto;
    }

    .service-hero-image {
        width: 100%;
    }

    .value-props-grid,
    .why-us-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .success-metrics {
        gap: 1rem;
    }

    .metric-item {
        width: 100%;
    }

    .services-grid {
        grid-template-columns: 1fr;
        padding: 0;
    }
    
    .service-card {
        margin: 10px 0;
    }
    
    .services-container {
        padding: 40px 20px;
    }
}

@media (max-width: 480px) {
    .service-hero,
    .value-props,
    .why-choose-us,
    .success-story,
    .service-cta {
        padding: 3rem 1rem;
    }

    .value-prop-item,
    .why-us-item {
        padding: 1.5rem;
    }
}
