/* Reset and clean slate Header.css */

/* Minimal Header Styles */
.app-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 20px 40px;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
}

.app-header.scrolled {
    padding: 15px 40px;
    background: rgba(0, 0, 0, 0.95);
}

.header-content {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Reset Logo Styles */
.header-logo {
    height: 65px;
    width: auto;
    max-width: none;
    margin-right: 20px;
    transition: transform 0.3s ease;
}

.header-logo:hover {
    transform: scale(1.05);
}

.nav-links {
    display: flex;
    gap: 40px;
    align-items: center;
}

.nav-link {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    padding: 5px 0;
}

.nav-link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, var(--accent), var(--accent2));
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
}

.nav-link:hover::after {
    transform: scaleX(1);
    transform-origin: left;
}

.header-cta {
    padding: 10px 25px;
    background: linear-gradient(90deg, var(--accent), var(--accent2));
    color: white;
    border: none;
    border-radius: 100px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.header-cta:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(255, 45, 85, 0.3);
}

@media (max-width: 768px) {
    .app-header {
        padding: 15px 20px;
    }
    
    .nav-links {
        display: none;
    }
}
